// main imports
import * as React from "react";
import { Link, Trans, useI18next } from "gatsby-plugin-react-i18next";

// plugin imports

// style imports
import "./Layout.scss";
import "../../styles/main.scss";

// svg imports
import BossaLogo from "../../svg/logo.svg";

// components imports

// component
export default function Layout({ children, local }) {
	const { t, language, originalPath } = useI18next();

	const logoTagline = {
		cafe: "café boutique.",
		pousada: "boutique pousada.",
	};

	const menu = {
		cafe: [
			{
				name: t("menus"),
				link: "cafe#menus",
			},
			{
				name: t("booking"),
				link: "cafe/reserva",
			},
			{
				name: t("private events"),
				link: "cafe/events",
			},
			{
				name: t("contact"),
				link: "cafe#contact",
			},
			{
				name: t("about"),
				link: "cafe#about",
			},
			{
				name: t("pousada"),
				link: "pousada",
			},
		],
		pousada: [
			{
				name: t("photos"),
				link: "pousada#photos",
			},
			{
				name: t("amenities"),
				link: "pousada#amenities",
			},
			{
				name: t("booking"),
				link: "pousada#contact",
			},
			{
				name: t("cafe"),
				link: "cafe",
			},
		],
	};

	return (
		<>
			<nav>
				<Link to={"/"}>
					<div className="navLogo">
						<BossaLogo />
						{logoTagline[local] && (
							<div className="logoSubtitle">{logoTagline[local]}</div>
						)}
					</div>
				</Link>

				{menu[local] && (
					<div className="navMenu bold">
						{menu[local].map((item, i) => (
							<div key={"menuIt" + i}>
								<span>
									<Link to={"/" + item.link}>{item.name}</Link>.
								</span>
							</div>
						))}
					</div>
				)}
			</nav>
			<main>{children}</main>

			<footer>
				<div className="languages">
					{language === "en" && (
						<Link to={originalPath} language={"es"}>
							web en español
						</Link>
					)}
					{language === "es" && (
						<Link to={originalPath} language={"en"}>
							english website
						</Link>
					)}
					.
				</div>

				<span>
					<a href="https://www.instagram.com/bossaestepona/">
						ig @bossaestepona
					</a>
					.
				</span>

				<span>
					<Link to="/legal">
						<Trans>legal advice and privacy policy</Trans>
					</Link>
					.
				</span>

				<span>
					<Trans>web by</Trans> <a href="https://peim.es">peim! studio</a>.
				</span>
			</footer>
		</>
	);
}
