// main imports
import * as React from "react";
import { graphql } from "gatsby";

// plugin imports
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

// style imports
import "../index.scss";
import "./legal.scss";

// svg imports
// import Favicon from "../images/favicon.svg";

// components imports
import Layout from "../../components/Layout/Layout.js";
import Seo from "../../components/Seo/Seo.js";

// page
export default function IndexPage() {
	const { language } = useI18next();

	return (
		<Layout>
			<Seo lang={language} />
			<div className="bodyContainer">
				<section>
					<div className="parallaxSection">
						<h2>
							<Trans ns="legal">legal advice</Trans>.
						</h2>
						<Trans ns="legal">
							Complying with the Act 34/2002 of 11th July regarding Company
							Services for Information and Electronic Commerce, we inform you
							that SELWOO EDGE, S.L., domiciled at AV. PARQUE SELWO, CALLE LIMA
							LOCAL 5, ESTEPONA, and tax number CIF: B42944215 in the Registro
							Mercantil de MÁLAGA T 6015, L 4922, F 61, S 8, H MA159695 is the
							title owner of this website.
						</Trans>
						<br></br>
						<br></br>
						<span className="bold">
							<Trans ns="legal">Data controller</Trans>.
						</span>
						<br></br>
						<Trans ns="legal">
							Where the personal data have been obtained from the data subject
							will be treated confidentially and will be registered in a record
							of processing activities for which SELWOO EDGE, S.L. is
							responsible
						</Trans>
						<br></br>
						<br></br>
						<span className="bold">
							<Trans ns="legal">
								Purpose of the processing of personal data
							</Trans>
							.
						</span>
						<br></br>
						<Trans ns="legal" i18nKey="purposeText">
							According to the provisions of the Spanish Organic Act 2018/03
							regarding Personal Data Protection and Regulation (EU) 2016/679,
							in the event that an e-mail is sent to us, or that a data
							collection form is filled in, we inform you that the personal data
							given to us will be registered in a record of processing
							activities for which SELWOO EDGE, S.L. is responsible.{" "}
							<span className="bold">
								The purpose of the same is:
								<br></br>• deal with the enquiry
								<br></br>• deal the booking
								<br></br>• provide our café boutique services
								<br></br>• maintain a specific commercial relationship
								<br></br>• send by any means including by e-mail, or other
								equivalent electronic communication, publicity or promotional
								information regarding products or services of the company, prior
								consent.
							</span>
						</Trans>
						<br></br>
						<br></br>
						<Trans ns="legal">
							SELWOO EDGE, S.L. declares that it has adopted all the necessary
							and relevant security measures as established in the Regulation
							(EU) 2016/679, and that it has established all technical methods
							within its means to avoid the loss, wrongful use, alteration,
							non-authorized access or theft of the data you may supply.
						</Trans>
						<br></br>
						<br></br>
						<span className="bold">
							<Trans ns="legal">
								Principles applied to the personal data provided
							</Trans>
							.
						</span>
						<br></br>
						<Trans ns="legal" i18nKey="principlesText">
							<span className="bold">
								Lawfulness, fairness and transparency principle
							</span>
							: We shall always request your prior consent to process your
							personal data for one or different specific purposes about which
							you will be previously informed with absolute transparency.
							<br></br>
							<span className="bold">Purpose limitation</span>: collected for
							specified, explicit and legitimate purposes and not further
							processed in a manner that is incompatible with those purposes.
							<br></br>
							<span className="bold">Data minimisation principle</span>: We
							shall request you only those personal data strictly necessary in
							relation to the purposes for which they are processed. The minimum
							possible data.
							<br></br>
							<span className="bold">Accurate</span>: and, where necessary, kept
							up to date;
							<br></br>
							<span className="bold">Storage limitation principle</span>:
							Personal data shall be kept for non longer than necessary for the
							purposes for which they are processed.
							<br></br>
							<span className="bold">
								Integrity and confidentiality principle
							</span>
							: Your personal data shall be processed in a manner that ensures
							appropriate security and confidentiality of the same. You must
							know that we always take all necessary measures against
							unauthorized or undue use of our users’ personal data by third
							parties.
							<br></br>
							<span className="bold">Accountability</span>: SELWOO EDGE, S.L.
							shall be responsible for, and be able to demonstrate compliance
							with, principles applied.
						</Trans>
						<br></br>
						<br></br>
						<span className="bold">
							<Trans ns="legal">Lawfulness of personal data processing</Trans>.
						</span>
						<br></br>
						<Trans ns="legal" i18nKey="lawfulnessText">
							SELWOO EDGE, S.L. is lawfully entitled to proceed to personal data
							processing in the following circumstances:
							<br></br>• The client has provided his/her personal data for
							pre-contractual or contractual relationships.
							<br></br>• The User or Client has given his/her informed consent
							to be sent commercial communications, for the installation of
							tracking systems to determine their browsing habits according to
							the Cookie Policy, or to receive the information requested through
							contact forms.
							<br></br>• There exist statutory obligations that require the
							processing of personal data according to the services rendered.
							<br></br>
							<br></br>
							In general, the legal basis that entitles us to process your
							personal data in our website is your CONSENT and if you are
							already one of our clients or members, then the legal basis is THE
							PERFORMANCE OF A CONTRACT. For further information about the legal
							basis for each of our data processing activities, please ask for
							our record of activities.
						</Trans>
						<br></br>
						<br></br>
						<span className="bold">
							<Trans ns="legal">
								Deadlines for the preservation of personal data
							</Trans>
							.
						</span>
						<br></br>
						<Trans ns="legal" i18nKey="deadlinesText">
							The personal data provided will be stored as long as the
							contractual relationship is maintained, it is not requested to be
							deleted by the User and should not be deleted as it is necessary
							for the fulfillment of a legal obligation or for legal exercise of
							rights.
							<br></br>
							If the User revokes his consent or exercises the restriction of
							processing or cancellation rights, his personal data will be kept
							available to the Administration of Justice during the legally
							established deadlines to attend to the possible responsibilities
							arising from the treatment of the same.
						</Trans>
						<br></br>
						<br></br>
						<span className="bold">
							<Trans ns="legal">
								Transfers and recipients of personal data
							</Trans>
							.
						</span>
						<br></br>
						<Trans ns="legal" i18nKey="transfersText">
							All the transfers indicated below are necessary for the
							fulfillment of the aforementioned purposes, or are carried out in
							compliance with a legal obligation. Personal data may be
							transferred to:
							<br></br>◦ Public Administrations and the Administration of
							Justice.
							<br></br>◦ IT service providers, including computer-based cloud
							computing services.
						</Trans>
						<br></br>
						<br></br>
						<span className="bold">
							<Trans ns="legal">
								Transfers of personal data to third countries
							</Trans>
							.
						</span>
						<br></br>
						<Trans ns="legal">
							The processing of your data is done, in general, by service
							providers located within the European Union or in countries that
							ensures an adequate level of protection, although sometimes, to
							carry out the purposes indicated above, certain companies that
							provide services to SELWOO EDGE, S.L., could access to your
							personal data (international data transfers). These transfers are
							made to countries with a level of protection comparable to that of
							the European Union (adaptation decisions of the European
							Commission, standard contractual clauses as well as certification
							mechanisms) and at all times we will ensure that whoever has that
							information to help us provide our services, it does so with all
							the guarantees in terms of data protection.
						</Trans>
						<br></br>
						<br></br>
						<span className="bold">
							<Trans ns="legal">Rights of the data subject</Trans>.
						</span>
						<br></br>
						<Trans ns="legal" i18nKey="subjectText">
							Users / clients may exercise their rights of access, rectification
							or cancellation, restriction of processing, objection,
							portability, and object to automated individual decision-making to
							SELWOO EDGE, S.L. Likewise, they may revoke their consent in case
							they have granted it for a specific purpose, being able to modify
							their preferences at all times.
							<br></br>
							They can be exercised through the email: info@bossaestepona.com or
							at the following address: AV. PARQUE SELWO, CALLE LIMA LOCAL 5,
							ESTEPONA. The User is informed that he/she can address any type of
							complaint regarding personal data protection to the Spanish Agency
							for Data Protection www.agpd.es, the Spanish State Supervisory
							Authority.  
						</Trans>
						<br></br>
						<br></br>
						<span className="bold">
							<Trans ns="legal">Children's data</Trans>.
						</span>
						<br></br>
						<Trans ns="legal">
							According to the provisions of the Spanish Organic Act regarding
							Personal Data Protection and Regulation (EU) 2016/679, our
							companies will not collect or process personal data of children
							under 14. If the child is under 14 years of age, such treatment
							shall only be considered if express authorization of his parents
							or lawful responsible.
						</Trans>
						<br></br>
						<br></br>
						<span className="bold">
							<Trans ns="legal">Personal data of third parties</Trans>.
						</span>
						<br></br>
						<Trans ns="legal">
							In the event that the personal data provided belong to a third
							party, the User guarantees that he has informed the third party of
							this Privacy Policy and has obtained his authorization to provide
							his data to SELWOO EDGE, S.L. for the purposes indicated. It also
							guarantees that the data provided are accurate and up to date,
							being responsible for any damages, direct or indirect, that could
							be caused as a result of breach of such obligation.
						</Trans>
						<br></br>
						<br></br>
						<span className="bold">
							<Trans ns="legal">Work with us / curriculum</Trans>.
						</span>
						<br></br>
						<Trans ns="legal" i18nKey="cvText">
							According to the provisions of the Spanish Organic Act 2018/03
							regarding Personal Data Protection and Regulation (EU) 2016/679
							any candidate which provide us personal data, consent is given by
							a clear affirmative act establishing a freely given, and consent
							cover all processing activities carried out for the same purpose
							or purposes. Candidate will be able to request a copy of his or
							her data, provided in a structured, commonly used.
							<br></br>
							We just store personal data kept up to date, so in any case your
							information is stored up to 24 months, data will be erased or
							block to prevent reading.
							<br></br>
							Our company has a strict privacy policy with applicants who send
							their CV. For this reason we inform you, that your personal data
							is stored in a registered file for which our company is
							responsible. Your data has been stored to enable SELWOO EDGE,
							S.L., to deal with recruitment process and carry out your
							professional offer. The purpose of processing your data is to
							manage your job application and the possible selection process,
							which may include your participation in various selection tests.
							<br></br>
							Your Data can be transferred to recruiting agencies and temporary
							employment agencies, enabling you to participate in other
							personnel selection opportunities. If your Data is incorrect,
							please let us know in writing, in order for us to correct it. You
							have 30 days to refuse, otherwise your consent will be assumed.
							You have the rights of access, rectification or cancellation,
							restriction of processing, objection, portability, and object to
							automate individual decision-making, in accordance with those
							established in the Data Protection Law.
						</Trans>
						<br></br>
						<br></br>
						<span className="bold">
							<Trans ns="legal" i18nKey="acosoTitle">
								Declaración de compromiso de prevención y erradicación del acoso
								digital
							</Trans>
							.
						</span>
						<br></br>
						<Trans ns="legal" i18nKey="acosoText">
							SELWOO EDGE, S.L. tenemos un compromiso con la protección de datos
							y con la igualdad de género y por ello nos comprometemos a
							garantizar el respeto en el tratamiento de datos personales a los
							principios recogidos en la normativa en materia de protección de
							datos, específicamente en el artículo 5 del Reglamento General de
							Protección de Datos.
							<br></br>
							Adquirimos así el compromiso de prevenir, sensibilizar y actuar en
							los supuestos que tengamos conocimiento de ciberacoso, ya sea este
							laboral o sexual.
							<br></br>
							SELWOO EDGE, S.L. a través de esta declaración de compromiso,
							tomamos conciencia de los riesgos que entrañan Internet y las TIC
							en lo relativo a este tipo de conductas e impulsamos una cultura
							de respeto a la intimidad de las personas y de concienciación en
							el empleo de datos personales.
							<br></br>
							«SELWOO EDGE, S.L. manifestamos nuestro profundo rechazo al acoso
							laboral y al acoso sexual o por razón de sexo y nuestros
							compromiso con la prevención y erradicación de estas conductas.
							SELWOO EDGE, S.L. nos oponemos frontalmente al empleo de datos
							personales que supongan tratamientos de datos ilícitos, que
							pudieran socavar el derecho a la intimidad y privacidad de los
							empleados. SELWOO EDGE, S.L. nos mostramos firmemente
							comprometidos con la protección de datos personales, necesaria
							para la salvaguarda al derecho fundamental al honor y a la
							intimidad personal y familiar de las personas. En consecuencia,
							respetamos los principios del artículo 5 del Reglamento General de
							Protección de Datos y minimizaremos los tratamientos de datos
							personales de nuestros trabajadores que puedan suponer un
							incremento del riesgo de conductas constitutivas de acoso. En
							cualquier caso, tendremos en cuenta el posible impacto en términos
							de género de los tratamientos que lleve a cabo».
						</Trans>
						<br></br>
						<br></br>
						<span className="bold">
							<Trans ns="legal">Contents</Trans>.
						</span>
						<br></br>
						<Trans ns="legal" i18nKey="contentsText">
							SELWOO EDGE, S.L. will not be responsible for infringements of
							intellectual property or industrial rights of third parties
							originating from the inclusion in the website of trademarks,
							business names, industrial designs, patents, designs, texts,
							photographs, graphics, logos, icons or software corresponding to
							third parties who, when including them in the website, have
							declared they are the title owners of the same. The user is
							obliged to use the contents of the website in a conscientious,
							correct and legal manner and undertakes:
							<br></br>
							a) Not to use the contents for objectives or purposes contrary to
							the law, to public morals and to normal customs or public order.
							<br></br>
							b) Not to reproduce, copy, distribute, allow public access by
							whatever means of public communication, transform or modify the
							contents, unless the corresponding authorization from the title
							owner thereof has been given.
							<br></br>
							c) Not to use the contents of the website to send publicity,
							communications for purposes of direct sales or for any other
							commercial purpose, unsolicited messages sent to a multitude of
							people regardless of its purpose, and to abstain from marketing or
							divulging such information in any way.
							<br></br>
							In the event of any kind of dispute, both parties will try to come
							to a friendly agreement. If this is not possible the Courts of
							Estepona will have jurisdiction to deal with the case and the
							parties will not have recourse to any other jurisdiction for the
							purposes of legal action.
						</Trans>
						<br></br>
						<br></br>
						<span className="bold">
							<Trans ns="legal">
								Who do not agree with this policy must not introduce any data
								into this website or access the content of this website
							</Trans>
							.
						</span>
					</div>
				</section>
			</div>
		</Layout>
	);
}

// graphql query
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
